import { render, staticRenderFns } from "./CallRecordsElectricityPrice.vue?vue&type=template&id=f641dc5e&scoped=true"
import script from "./CallRecordsElectricityPrice.vue?vue&type=script&lang=js"
export * from "./CallRecordsElectricityPrice.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f641dc5e",
  null
  
)

component.options.__file = "CallRecordsElectricityPrice.vue"
export default component.exports